import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { REGEX } from './regex.constants';

export const VALIDATORS = {
  REQUIRED: RxwebValidators.required(),
  EMAIL: RxwebValidators.email(),

  // alphanumeric
  ALPHANUMERIC_WITH_SPACE: RxwebValidators.pattern({ expression: { alphaNumericSpace: REGEX.ALPHANUMERIC_WITH_SPACE }}),
  ALPHANUMERIC_WITH_DASH: RxwebValidators.pattern({ expression: { alphaNumericDash: REGEX.ALPHANUMERIC_WITH_DASH }}),
  ALPHANUMERIC_WITH_SPACE_DASH: RxwebValidators.pattern({ expression: { alphaNumericSpaceDash: REGEX.ALPHANUMERIC_WITH_DASH_SPACE }}),
  NUMERIC_WITH_PLUS: RxwebValidators.pattern({ expression: { numericWithPlus: REGEX.NUMERIC_WITH_PLUS }}),
  NUMERIC_WITH_PLUS_SPACE_DASH: RxwebValidators.pattern({ expression: { numericWithPlusDashSpace: REGEX.NUMERIC_WITH_PLUS_DASH_SPACE }}),
  ALPHANUMERIC: RxwebValidators.alphaNumeric(),
  NUMERIC: RxwebValidators.numeric(),

  // max length
  MAX_LENGTH: RxwebValidators.maxLength({ value: 250 }),
  ASSET_ID_MAX_LENGTH: RxwebValidators.maxLength({ value: 20 }),
}

export const MIN_LENGTH = (size: number) => RxwebValidators.minLength({ value: size });
export const MAX_LENGTH = (size: number) => RxwebValidators.maxLength({ value: size });