import { LABELS } from './labels.constants';

export const CustomerAccountStatusLabel: Record<string, string> = {
  ['true']: LABELS.active,
  ['false']: LABELS.inactive
}

export const UserStatus = {
  Active: true,
  Inactive: false
}